import { InlineTimeInput } from '../../../common/components/inputs/InlineTimeInput';
import { NextDayIcon } from '../../../common/components/svg/NextDayIcon';
import { TimeRange } from './DurationInMinutes';
import { Box, Stack, Tooltip, useTheme } from '@mui/material';
import { Ref, useCallback } from 'react';
import { getTimeRangeDurationInMinutes } from '../../../common/helpers/TimeRangeHelpers';
import { computedTo } from '../../../common/helpers/DateTimeHelper';

interface TimeRangeControlProps<T extends TimeRange> {
  name: string;
  value: T['from'];
  onBlur: () => void;
  onChange: (value: T['from']) => void;
  onToggleNextDay: () => void;
  parentDate?: Date;
  nextDay: boolean;
  errorMessage: string | undefined;
  readonly: boolean;
  durationInMinutes: number;
  onDurationInMinutesChange: (value: number) => void;
  myRef?: Ref<HTMLInputElement>;
}

/**
 * @deprecated stop using this reference, use the one in common.
 * @note Move me to common folder and remove the deprecated tag
 */
export const From = <T extends TimeRange>({
  name,
  value,
  onBlur,
  onChange,
  parentDate = new Date(),
  errorMessage,
  readonly,
  myRef,
  onToggleNextDay,
  durationInMinutes,
  onDurationInMinutesChange,
  nextDay,
}: TimeRangeControlProps<T>) => {
  const theme = useTheme();

  const handleToggleNextDay = useCallback(() => {
    onToggleNextDay();
    onDurationInMinutesChange(getTimeRangeDurationInMinutes(value, computedTo(value, durationInMinutes, parentDate), parentDate));
  }, [durationInMinutes, onDurationInMinutesChange, onToggleNextDay, parentDate, value]);

  const handleFromChange = useCallback(
    (newValue: string) => {
      if (value === newValue) return;
      onDurationInMinutesChange(getTimeRangeDurationInMinutes(newValue, computedTo(value, durationInMinutes, parentDate), parentDate));
      onChange(newValue);
    },
    [durationInMinutes, onChange, onDurationInMinutesChange, parentDate, value],
  );

  return (
    <Tooltip disableInteractive title={errorMessage ?? ''} placement='top' arrow>
      <Stack
        direction='row'
        sx={{
          ...(errorMessage && {
            color: theme.palette.error.main,
            borderBottom: `1px solid ${theme.palette.error.main}`,
          }),
        }}>
        <InlineTimeInput
          ref={myRef}
          name={name}
          disabled={readonly}
          onChange={handleFromChange}
          onBlur={onBlur}
          value={value}
          error={!!errorMessage}
          style={{
            ...(errorMessage && {
              color: theme.palette.error.main,
            }),
          }}
        />
        <Box sx={{ paddingTop: '5px' }}>
          <NextDayIcon
            aria-label={name + '.tomorrow'}
            disabled={readonly}
            onClick={handleToggleNextDay}
            active={nextDay}
            dateToIncrement={parentDate}
          />
        </Box>
      </Stack>
    </Tooltip>
  );
};
