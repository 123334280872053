import { AbilityBuilder } from '@casl/ability';
import { AppAbility } from '../../authorization/Ability';
import { Role } from '../../common/models/models';

const viewTimesheetRoles: Role[] = ['dispatcher', 'admin', 'billingClerk', 'dispatchChief'];
export const timesheetAbility = 'timesheet';

export const defineTimesheetAbilities = (abilityBuilder: AbilityBuilder<AppAbility>, roles: Role[] | undefined): void => {
  const { can } = abilityBuilder;

  if (roles?.some((role: Role) => viewTimesheetRoles.includes(role))) can('view', timesheetAbility);
};
