import { AbilityBuilder } from '@casl/ability';
import { AppAbility } from './Ability';
import { AxiosError } from 'axios';
import { Role } from '../common/models/models';

const appSupportedRoles: Role[] = [
  'dispatcher',
  'billingClerk',
  'admin',
  'salesRepresentative',
  'salesSupervisor',
  'salesDirector',
  'salesAdmin',
  'salesSuperUser',
  'dispatchChief',
  'dispatchReadOnly',
  'validationReadOnly',
];

export const appAbility = 'app';

export const defineAppAbilities = (
  abilityBuilder: AbilityBuilder<AppAbility>,
  roles: Role[] | undefined,
  error: AxiosError | null | undefined,
): void => {
  const { can } = abilityBuilder;

  const isAuthorized = !error || (error.response?.status !== 401 && error.response?.status !== 403);
  const employeeHasAnySupportedRole = !error && !!roles?.some((role) => appSupportedRoles.includes(role));

  if (isAuthorized && employeeHasAnySupportedRole) {
    can('access', appAbility);
  }
};
