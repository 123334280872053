import { AbilityBuilder } from '@casl/ability';
import { AppAbility } from '../../authorization/Ability';
import { Role } from '../../common/models/models';

const accessConfigurationRoles: Role[] = ['dispatcher', 'admin', 'billingClerk', 'dispatchChief'];

export const configurationAbility = 'configuration';

export const defineConfigurationAbilities = (abilityBuilder: AbilityBuilder<AppAbility>, roles: Role[] | undefined): void => {
  const { can } = abilityBuilder;

  if (!!roles?.some((role) => accessConfigurationRoles.includes(role))) can('view', configurationAbility);
};
