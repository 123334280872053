import { AbilityBuilder } from '@casl/ability';
import { AppAbility } from '../../authorization/Ability';
import { Role } from '../../common/models/models';

export const dispatchAbility = 'dispatch';
export const dispatchResourceAbility = 'dispatchResource';
type DispatchAbilityContext = {
  branchIds: string[];
};

const canUserEditAllBranches = (branchIds: string[], userBranchIds: string[] | undefined): boolean => {
  if (!branchIds.length || !userBranchIds) return false;
  return branchIds.every((branchId) => userBranchIds?.some((userBranchId) => userBranchId === branchId));
};

const canUserEditAnyBranches = (branchIds: string[], userBranchIds: string[] | undefined): boolean => {
  if (!branchIds.length || !userBranchIds) return false;
  return branchIds.some((branchId) => userBranchIds?.some((userBranchId) => userBranchId === branchId));
};

export const defineDispatchAbilities = (
  abilityBuilder: AbilityBuilder<AppAbility>,
  roles: Role[] | undefined,
  userBranchIds: string[] | undefined,
): void => {
  const { can } = abilityBuilder;

  if (roles?.some((role: Role) => role === 'admin' || role === 'dispatchChief')) {
    can(['edit', 'optimizeSingleBranch', 'optimizeInterBranch', 'finalize'], dispatchAbility);
    can('edit', dispatchResourceAbility);
  } else if (roles?.some((role: Role) => role === 'dispatcher')) {
    can('optimizeSingleBranch', dispatchAbility);
    can('edit', dispatchAbility, ({ branchIds }: DispatchAbilityContext) => canUserEditAllBranches(branchIds, userBranchIds));
    can('edit', dispatchResourceAbility, ({ branchIds }: DispatchAbilityContext) => canUserEditAnyBranches(branchIds, userBranchIds));
  } else if (roles?.some((role: Role) => role === 'billingClerk')) {
    can('edit', dispatchAbility, ({ branchIds }: DispatchAbilityContext) => canUserEditAllBranches(branchIds, userBranchIds));
  }
};
