import { AbilityBuilder } from '@casl/ability';
import { AppAbility } from '../../authorization/Ability';
import { Role } from '../../common/models/models';

const accessNotificationsRoles: Role[] = ['admin', 'billingClerk', 'dispatchChief', 'dispatcher'];

export const notificationsAbility = 'notifications';

export const defineAcknowledgmentsAbilities = (abilityBuilder: AbilityBuilder<AppAbility>, roles: Role[] | undefined): void => {
  const { can } = abilityBuilder;

  if (!!roles?.some((role) => accessNotificationsRoles.includes(role))) can('view', notificationsAbility);
};
