import {
  AccountInfo,
  AuthenticationResult,
  EventMessage,
  EventType,
  InteractionStatus,
  PublicClientApplication,
} from '@azure/msal-browser';
import { MsalProvider, useIsAuthenticated, useMsal } from '@azure/msal-react';

import React, { PropsWithChildren, useEffect, useLayoutEffect } from 'react';
import { loginRequest, msalConfig } from './authConfig';
import { PageLoader } from './layout/components/PageLoader';
import { config } from './config';

const lastUsedAccountStorageKey = `lastUsedAccount_${config.APP_VERSION}`;

const msalEventCallback = async (event: EventMessage) => {
  console.info('MSAL Event type:', event.eventType, ' -- MSAL interaction type:', event.interactionType);

  switch (event.eventType) {
    case EventType.LOGIN_SUCCESS: {
      const payloadAccount = event.payload as AuthenticationResult;
      if (payloadAccount?.account) {
        localStorage.setItem(lastUsedAccountStorageKey, payloadAccount.account.homeAccountId);
        msalInstance.setActiveAccount(payloadAccount.account);
      }
      break;
    }
    case EventType.ACQUIRE_TOKEN_SUCCESS: {
      const payloadToken = event.payload as AuthenticationResult;
      if (payloadToken?.account) {
        localStorage.setItem(lastUsedAccountStorageKey, payloadToken.account.homeAccountId);
        msalInstance.setActiveAccount(payloadToken.account);
      }
      break;
    }
    case EventType.LOGOUT_SUCCESS:
      localStorage.removeItem(lastUsedAccountStorageKey);
      msalInstance.setActiveAccount(null);
      break;
    case EventType.LOGIN_FAILURE:
    case EventType.LOGOUT_FAILURE:
      msalInstance.setActiveAccount(null);
      await msalInstance.clearCache();
      localStorage.removeItem(lastUsedAccountStorageKey);
      break;
    case EventType.ACQUIRE_TOKEN_FAILURE:
      msalInstance.setActiveAccount(null);
      await msalInstance.clearCache();
      localStorage.removeItem(lastUsedAccountStorageKey);
      if (event.interactionType !== InteractionStatus.None) return;
      await msalInstance.loginRedirect(loginRequest);
      break;
    default:
      break;
  }
};

const AuthenticationWrapper: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { instance, inProgress, accounts } = useMsal();
  const isAuthenticated = useIsAuthenticated();

  useLayoutEffect(() => {
    const token = instance.addEventCallback(msalEventCallback);
    return () => {
      if (!token) return;
      instance.removeEventCallback(token);
    };
  }, [instance]);

  useEffect(() => {
    if (inProgress !== InteractionStatus.None) return;
    instance.initialize().then(async () => {
      if (accounts.length > 0) {
        try {
          // Try to silently acquire a token for the last used account
          const lastUsedAccount = getLastUsedAccount(accounts);
          if (lastUsedAccount) {
            await instance.acquireTokenSilent({
              ...loginRequest,
              account: lastUsedAccount,
            });
          } else {
            // No last used account found, redirect to login
            await instance.loginRedirect(loginRequest);
          }
        } catch (error) {
          console.error('Token acquisition failed:', error);
        }
      } else {
        // No accounts found, redirect to login
        await instance.loginRedirect(loginRequest);
      }
    });
  }, [instance, inProgress, accounts]);

  if (isAuthenticated) {
    return <>{children}</>;
  }

  return <PageLoader />;
};

// Helper function to get the last used account
const getLastUsedAccount = (accounts: AccountInfo[]): AccountInfo | null => {
  const lastUsedAccountId = localStorage.getItem(lastUsedAccountStorageKey);
  if (lastUsedAccountId) {
    return accounts.find((account) => account.homeAccountId === lastUsedAccountId) || null;
  }
  return accounts[0] || null;
};

const msalInstance = new PublicClientApplication(msalConfig);

export const Authenticator = ({ children }: PropsWithChildren) => {
  return (
    <MsalProvider instance={msalInstance}>
      <AuthenticationWrapper>{children}</AuthenticationWrapper>
    </MsalProvider>
  );
};
